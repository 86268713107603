import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './ChessviaAdvantages.module.css';
import advantage1 from '../assets/new/S6_safesecuretrans.png';
import advantage2 from '../assets/new/S5_getverified_new.png';
import advantage3 from '../assets/new/S6_affordablepersonalized.png';
import advantage4 from '../assets/new/S4_receiveoffers_new.png';
import advantage5 from '../assets/new/S5_choosehowpaid_new.png';

const ChessviaAdvantages = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <h2 className={css.columnTitle}>The Chessvia advantage</h2>
      <div className={classes}>
        <div className={css.cardsRow}>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img src={advantage3} alt="AI Matching System" />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>AI Matching System</div>
                <div className={css.thumbText}>Proprietary algorithm matching players with the coaches best suited for them</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img src={advantage1} alt="ChessviaGPT" />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>ChessviaGPT Chatbot</div>
                <div className={css.thumbText}>A free personalized chess chatbot that's trained on your games and account</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img src={advantage2} alt="Asynchronous Services" />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Asynchronous Coaching</div>
                <div className={css.thumbText}>Schedule asynchronous coaching sessions and learn at your pace and style</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img src={advantage4} alt="Requests" />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Custom requests</div>
                <div className={css.thumbText}>Make personalized chess requests where coaches can place bids - eBay style</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img src={advantage5} alt="Chessvia Affiliate" />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Chessvia Affiliate</div>
                <div className={css.thumbText}>An affiliate program that lets you to learn and earn while helping grow the community</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ChessviaAdvantages.defaultProps = {
  className: null,
  rootClassName: null,
};

ChessviaAdvantages.propTypes = {
  className: string,
  rootClassName: string,
};

export default ChessviaAdvantages;
