import React, { useEffect } from 'react';
import { bool, object, string } from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { loadData } from '../RecommendedUsers/RecommendedUsersPage.duck';
import AsideTabPanel from './AsideTabPanel';
import AuthDetailPanel from './AuthDetailPanel';
import RecommendedUsers from '../RecommendedUsers/RecommendedUsers.js';
import css from './AuthLandingPage.module.css';
import { ensureCurrentUser } from '../../../util/data';

const AuthLandingPage = props => {
  const { 
    className, 
    rootClassName, 
    currentPage,
    currentUser,
    isReviewer,
  } = props;
  
  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  return (
    <>
      <div className={css.dashboardWrapper}>
        <div className={css.dashboardSection}>
          <AsideTabPanel
            currentPage={currentPage}
            currentUser={user}
            isReviewer={isReviewer}
          />
          
          <div className={css.mainPanel}>
            <AuthDetailPanel
              currentPage={currentPage}
              currentUser={user}
              isReviewer={isReviewer}
            />
            <RecommendedUsers/>
          </div>
        </div>
      </div>
      
    </>
  );
};

AuthLandingPage.defaultProps = {
  className: null,
  rootClassName: null,
  currentPage: null,
};

AuthLandingPage.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default AuthLandingPage;
