import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import css from './ChessviaCoaching.module.css';

const ChessviaCoaching = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <h2 className={css.columnTitle}>Chessvia is made for everyone</h2>
      <div className={css.columnSubTitle}>Join us and discover.....</div>
      <div className={css.coachingWrapper}>
        Content Here
      </div>
    </>
  );
};

ChessviaCoaching.defaultProps = {
  className: null,
  rootClassName: null,
};

ChessviaCoaching.propTypes = {
  className: string,
  rootClassName: string,
};

export default ChessviaCoaching;
