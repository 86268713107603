import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import css from './FeaturedCoaches.module.css';
import Vasishta_Chessvia_Image from '../assets/Vasishta_Chessvia_Image.jpg';
import Vishal_Chessvia_Image from '../assets/Vishal_Chessvia_Image.jpg';
import Naveen_Chessvia_Image from '../assets/Naveen_Chessvia_Image.jpg';
import Thomas_Chessvia_Image from '../assets/Thomas_Chessvia_Image.jpg';

const FeaturedCoaches = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
    <h2 className={css.columnTitle}>Meet some of the coaches</h2>
      <div className={css.featuredCoaches}>
        <a className={css.ListingCard} href="">
          <div className={css.AspectRatioWrapper}>
            <div className={css.aspectPadding}>
              <div className={css.aspectBox}>
                <div className={css.imgBox}>
                  <img src={Thomas_Chessvia_Image} alt="eodnT" className={css.rootForImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              <div className={css.priceValue}></div>
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>Thomas Kung</div>
              <div className={css.authorInfo}><span>NM</span></div>
              <div className={css.authorInfo}><span>12 years of experience</span></div>
            </div>
          </div>
        </a>
        <a className={css.ListingCard} href="">
          <div className={css.AspectRatioWrapper}>
            <div className={css.aspectPadding}>
              <div className={css.aspectBox}>
                <div className={css.imgBox}>
                  <img src={Naveen_Chessvia_Image} alt="eodn" className={css.rootForImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              <div className={css.priceValue}></div>
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>Naveen Prabhu</div>
              <div className={css.authorInfo}><span>NM</span></div>
              <div className={css.authorInfo}><span>12 years of experience</span></div>
            </div>
          </div>
        </a>
        <a className={css.ListingCard} href="">
          <div className={css.AspectRatioWrapper}>
            <div className={css.aspectPadding}>
              <div className={css.aspectBox}>
                <div className={css.imgBox}>
                  <img src={Vasishta_Chessvia_Image} alt="eodn" className={css.rootForImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              <div className={css.priceValue}></div>
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>Vasishta Tumuluri</div>
              <div className={css.authorInfo}><span>1900 USCF</span></div>
              <div className={css.authorInfo}><span>8 years of experience</span></div>
            </div>
          </div>
        </a>
        <a className={css.ListingCard} href="">
          <div className={css.AspectRatioWrapper}>
            <div className={css.aspectPadding}>
              <div className={css.aspectBox}>
                <div className={css.imgBox}>
                  <img src={Vishal_Chessvia_Image} alt="eodn" className={css.rootForImage} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              <div className={css.priceValue}></div>
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>Vishal Balyan</div>
              <div className={css.authorInfo}><span>2000 USCF</span></div>
              <div className={css.authorInfo}><span>9 years of experience</span></div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

FeaturedCoaches.defaultProps = {
  className: null,
  rootClassName: null,
};

FeaturedCoaches.propTypes = {
  className: string,
  rootClassName: string,
};

export default FeaturedCoaches;
