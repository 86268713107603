import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import { Page, NamedLink } from '../../components';
import classNames from 'classnames';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import FeaturedCoaches from './FeaturedCoaches/FeaturedCoaches';
import ChessviaAdvantages from './ChessviaAdvantages/ChessviaAdvantages';
import ChessviaTestimonial from './ChessviaTestimonial/ChessviaTestimonial';
import ChessviaCoaching from './ChessviaCoaching/ChessviaCoaching';
import MiniFaq from './MiniFaq/MiniFaq';
import AuthLandingPage from './AuthLandingPage/AuthLandingPage';

import ChatbotForm from './ChatbotPage2/ChatbotForm';
//import TypeWriterEffect from 'react-typewriter-effect';
import Typewriter from 'typewriter-effect';

import css from './LandingPage.module.css';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';

import whyUse1 from './assets/aboutus4-removebg-preview.png';
import whyUse2 from './assets/S2_textreviews_new.png';
import whyUse3 from './assets/s6_getreviewedbypros.png';
import howWork1 from './assets/howWork1.png';
import howWork2 from './assets/howWork2.png';
import howWork3 from './assets/howWork3.png';
import howWork4 from './assets/howWork4.png';
import wideSelect1 from './assets/wideSelect1.png';
import wideSelect2 from './assets/wideSelect2.png';
import wideSelect3 from './assets/wideSelect3.png';

import ScrollAnimation from 'react-animate-on-scroll';

export const LandingPageComponent = props => {
  const {
    isAuthenticated,
    currentUser,
  } = props;
  const isReviewer = isAuthenticated && currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  const title = "Chessvia";
  const scrollingDisabled = false;

  const topHero = (
    <div className={css.topHero}>
      <div className={css.topHeroText}>
        <h1 className={css.topHeroTitle}>Chess Coaching Reimagined</h1>
        <div className={css.typewriterContainer}>
        <Typewriter
          options={{
            strings: ['Chessvia is an AI-driven Chess marketplace connecting coaches and players from across the world to learn and earn together'],
            autoStart: true,
            loop:true,
            deleteSpeed: 20,
            pauseFor: 4000,
            delay: 25, // Adjust this value to match your previous typeSpeed
          }}
        />
        </div>
      </div>
      <div className={css.topHeroButtonsContainer}> {/* This is the existing div container for buttons */}
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
          { isAuthenticated ? (
            isReviewer ? (
              <NamedLink name="SearchPage" to={{search: '?pub_listingType=review-service'}} className={css.button}>
                Browse Services
              </NamedLink>
            ) : (
              <NamedLink name="SearchPage" to={{search: '?pub_listingType=review-request'}} className={css.button}>
                Browse Requests
              </NamedLink>
            )
          ) : (
            <>
              <NamedLink name="HowItWorks" className={css.howChessviaWorksbutton}>
                How Chessvia Works
              </NamedLink>
              <NamedLink name="SignupPage" className={css.signUpbutton}>
                Sign Up
              </NamedLink>
            </>
          )}
        </ScrollAnimation>
      </div>
    </div>
  );

  const whyUse = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <h2 className={css.columnTitle}>Why use Chessvia?</h2>
        <div className={css.columnGroup}>
          <div className={classNames(css.columnGroupInner, css.columnGroupInnerCustom)}>
            <div className={css.column}>
              <img className={css.columnImg} src={whyUse3} alt="whyUse1" />
              <h4 className={css.columnText}>Global network of expert coaches</h4>
              <p className={css.columnText}>High-quality Chess reviews from high-quality reviewers from across the world</p>
            </div>
            <div className={css.column}>
              <img className={css.columnImg} src={whyUse2} alt="whyUse2"/>
              <h4 className={css.columnText}>Affordable & personalized coaching</h4>
              <p className={css.columnText}>Reviews tailored to your level, playing style, and goals without breaking the bank</p>
            </div>
            <div className={css.column}>
              <img className={css.columnImg} src={whyUse1} alt="whyUse3"/>
              <h4 className={css.columnText}>Safe & secure transactions</h4>
              <p className={css.columnText}>We've got your back! Get your money back in full if you're not fully satisfied with your review</p>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )

  const featuredCoaches = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <FeaturedCoaches />
      </ScrollAnimation>
    </>
  )

  const chessviaAdvantages = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <ChessviaAdvantages />
      </ScrollAnimation>
    </>
  )

  const chessviaTestimonial = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <ChessviaTestimonial />
      </ScrollAnimation>
    </>
  )

  const chessviaCoaching = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <ChessviaCoaching />
      </ScrollAnimation>
    </>
  )

  const miniFaq = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <MiniFaq />
      </ScrollAnimation>
    </>
  )  

  const arrowRight = (
    <svg className={css.arrowRight} width='70' height='70' viewBox='30 0 130 200' xmlns="http://www.w3.org/2000/svg">
      <path
        d='M54,2.06a5.33,5.33,0,0,1,3.76,1.55l91.72,91.72a6.62,6.62,0,0,1,0,9.33L57.71,196.39a5.31,5.31,0,1,1-7.51-7.52L139.07,100,50.2,11.13A5.31,5.31,0,0,1,54,2.06Zm88,100.8v0Z'
      />
    </svg>
  )

  
  const howDoesItWork = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <h2 className={css.columnTitle}>How does it work?</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
        <div className={css.columnGroup}>
          <div className={classNames(css.columnGroupInner, css.columnGroupInnerCustom)}>
            <div className={css.column}>
              <img className={css.columnImg} src={howWork1} alt="howWork1" />
              <p className={css.columnText}>Get a free scan by Chessvia Chessalyzer</p>
            </div>
            {arrowRight}
            <div className={css.column}>
              <img className={css.columnImg} src={howWork2} alt="howWork2"/>
              <p className={css.columnText}>Get matched with services and coaches tailored to you</p>
            </div>
            {arrowRight}
            <div className={css.column}>
              <img className={css.columnImg} src={howWork3} alt="howWork3"/>
              <p className={css.columnText}>Book the service</p>
            </div>
            {arrowRight}
            <div className={css.column}>
              <img className={css.columnImg} src={howWork4} alt="howWork4"/>
              <p className={css.columnText}>Get the review delivered to your inbox in no time</p>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )

  const coachSelection = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <h2 className={css.columnTitle}>Wide selection of coaches</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
        <div className={css.columnGroup}>
          <div className={classNames(css.columnGroupInner, css.columnGroupInnerCustom)}>
            <div className={css.column}>
              <img className={css.columnImg} src={wideSelect1} alt="wideSelect1" />
              <p className={css.columnText}>X coaches from Y rating to Z rating</p>
            </div>
            <div className={css.column}>
              <img className={css.columnImg} src={wideSelect2} alt="wideSelect2"/>
              <p className={css.columnText}>Coaches from X countries that speak Q languages</p>
            </div>
            <div className={css.column}>
              <img className={css.columnImg} src={wideSelect3} alt="wideSelect3"/>
              <p className={css.columnText}>Coaches starting as low as $X per hour</p>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )
/*
  const callToAction = (
    <>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <h2 className={css.columnTitle}>Ready to start?</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
        <div className={css.columnGroup}>
          <div className={css.columnGroupInner}>
            <NamedLink name="HowItWorks" className={css.button} >
              How Chessvia Works
            </NamedLink>
            <NamedLink name="SignupPage" className={css.button} >
              Join us now
            </NamedLink>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )
*/
  const callToAction = (
    <div className={css.callToActionSection}>
      <div className={css.callToActionTextBox}>
        <div className={css.textWrapper}>
          <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
            <div className={css.titleText}>
              <h2>Ready to start?</h2>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
            <div className={css.descriptionText}>
              <NamedLink name="SignupPage" className={css.button}>
                Join us now
              </NamedLink>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div className={css.callToActionImgBox}>
        {/* Image to add */}
        <img src="https://play-lh.googleusercontent.com/bpdkCCI8e5IbAkLgRwI3e5dJDSR_kFe1Ioh7f7_AxxaR_Jn9VHF1Hx56DEAqGnHPynmd" alt="img" className={css.actionImg} />
      </div>
    </div>
  )
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        currentPage="LandingPage"
      />

      { isAuthenticated ? (
        <AuthLandingPage 
          currentUser={currentUser} 
          isReviewer={isReviewer}
        /> 
      ) : (
        <>
          {topHero}
          <div className={css.mainPage}>
            {whyUse}
            {/*
              {featuredCoaches}
            */}
            {chessviaAdvantages}
            {/*
            {chessviaTestimonial}
            */}
            <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
              <ChatbotForm />  {/* Correctly rendered ChatbotForm component */}
            </ScrollAnimation>    
            {miniFaq}
            {/*
              {chessviaCoaching}
              {howDoesItWork}
              {coachSelection}
              {callToAction}
            */}

          </div>
        </>
      )}

      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <FooterContainer />
      </ScrollAnimation>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  // Page needs isAuthenticated
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
