import React, { useState } from 'react'; // Add useState to the import from 'react'


import chatbotIcon from './images/aboutus4.png'; 
import accessImage from './images/aboutus6 (1).png'; 
import image1 from './images/S2_textreviews_new.png'; 
import image2 from './images/S1_1on1coaching_new.png';
import image3 from './images/S3_selectacoach_new.png'; 
import image4 from './images/S3_browsecoaches_new.png';
import isometric from './images/isometric.png';
import { FormattedMessage } from '../../../util/reactIntl.js';

import Chatwiththefirsteveraichesschatbot from './images2/Chatwiththefirsteveraichesschatbot.png';
import Compareyourselftootherplayers from './images2/Compareyourselftootherplayers.png';
import Easytouseandaccess from './images2/Easytouseandaccess.webp';
import Findyourpersonalizedcodeinyouremail from './images2/Findyourpersonalizedcodeinyouremail.png';
import Trainedonyourgames from './images2/Trainedonyourgames.webp';
import Trainthechatbotonyourchessaccount from './images2/Trainthechatbotonyourchessaccount.png';
import VisitchessviaGPTlinkandshareyourcode from './images2/VisitchessviaGPTlinkandshareyourcode.png';
import S6_safesecuretrans from './images2/S6_safesecuretrans.png';


import { bool, string } from 'prop-types';
import css from './Chatbot.module.css';


const ChatbotForm = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = { username, email };
    const webhookUrl = 'https://hook.us1.make.com/zqsd7fjt9vany6qlpj26ytjvhm4d3fy0';

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        setSubmitted(true);
        // Reset form fields here if you want
        setUsername('');
        setEmail('');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  return (
    <div className={css.joinUsSection}>
      <div className={css.joinUsInner}>
        <div className={css.joinUsBox}>
          <div className={css.joinUsText}>
            <div className={css.headingJoinUs}>
              <FormattedMessage id="ChatbotPage.joinUsHeading" defaultMessage="Check out ChessviaGPT for free" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="ChatbotPage.joinUsSubHeading" defaultMessage="Train ChessviaGPT on your chess account in minutes" />
            </div>
          </div>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Chess.com username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={css.inputField}
            />
            <input
              type="email"
              placeholder="Email to deliver your access code"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={css.inputField}
            />
            <button type="submit" className={css.joinUsBtn}>
              <FormattedMessage id="ChatbotPage.joinUsBtn" defaultMessage="Train ChessviaGPT" />
            </button>
            {submitted && (
              <span className={css.submissionMessage}>
                Awesome! You will receive your personalized ChessviaGPT access link and code in a few minutes.
              </span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

ChatbotForm.defaultProps = {
  className: null,
  rootClassName: null,
};

ChatbotForm.propTypes = {
  className: string,
  rootClassName: string,
};

export default ChatbotForm;