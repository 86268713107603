import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import css from './MiniFaq.module.css';

const MiniFaq = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.questionSection}>
      <div className={css.questionInner}>
        <h2 className={css.columnTitle}>Still have some questions?</h2>
        <Accordion className={css.accordionBox}>
          <AccordionItem className={css.accordionItem}>
            <AccordionHeader>
              <h3 className={css.accordionTitle}>
                What is Chessvia?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>Chessvia is the first AI-driven Chess education marketplace for players to connect, learn, and earn. We make it easier, safer, and more affordable for Chess players to connect with one another and earn from the game we all love
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                Is Chessvia just for coaching?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>No! Chessvia is flexible so that you can find the coaches and services you need for your style, your pace, and your budget. We offer AI reviews, video revies, live coaching,  and whatever else you can think of
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                Is Chessvia free?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>Yes! As part of our goal to democratize chess education, signing up, using our free chatbot and browsing coaches and services is completely free. Our broad selection of coaches offer services to cater to all price ranges.
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                Who can use Chessvia?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>Just about the entire world! Our payment solutions let Chess players safely and securely transact from almost any country on earth.
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                What AI tools are available?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>All players are offered a free Chessalyzer intelligent matching scan to pair you with a coach based on your learning, language, budget or other requirements. You can also create a personalized Chess chatbot trained on your account and we have big plans for our AI products to help you reach your chess goals!</div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                What types of Chess services can I buy?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>Whatever you can think of! Chesvia offers a range of coach provided services and AI tools. Coaches offer both synchronous and asynchronous services, including but not limited to: game reviews, account reviews, tournament reviews, training plans and 1on1 tutoring.</div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader className={css.accordionItem}>
              <h3 className={css.accordionTitle}>
                How can I become a Chessvia coach? Are there any limitations?
              </h3>
              <div className={css.plusToggle}>
                <span></span>
                <span></span>
              </div>
            </AccordionHeader>
            <AccordionBody className={css.accordionBodyBox}>
              <div className={css.accordionBody}>Apply in the top bar! After verifying your rating, you will be able to list services, coach players, and get paid to share your knowledge about the game that we all love. As part of our mission to democratize Chess education, we believe that anyone who wants to teach should be able to.</div>
            </AccordionBody>
          </AccordionItem>

        </Accordion>
      </div>
    </div>
  );
};

MiniFaq.defaultProps = {
  className: null,
  rootClassName: null,
};

MiniFaq.propTypes = {
  className: string,
  rootClassName: string,
};

export default MiniFaq;
