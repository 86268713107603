import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './ChessviaTestimonial.module.css';
import Vasishta_Chessvia_Image from '../assets/Vasishta_Chessvia_Image.jpg';
import Vishal_Chessvia_Image from '../assets/Vishal_Chessvia_Image.jpg';
import Naveen_Chessvia_Image from '../assets/Naveen_Chessvia_Image.jpg';
import Thomas_Chessvia_Image from '../assets/Thomas_Chessvia_Image.jpg';
import Rohan_Chessvia_Image from '../assets/Rohan_Chessvia_Image.jpg';
import Trevor_Chessvia_Image from '../assets/trevorimage.png';
import Raleigh_Chessvia_Image from '../assets/1700957273537.png';
import Vishal_Chessvia_Image2 from '../assets/1646285769812.png';

const ChessviaTestimonial = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <h2 className={css.columnTitle}>Check out what Chessvia users have to say</h2>
      <div className={css.advantageWrapper}>
        <div className={css.cardsRow}>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img
                src={Vishal_Chessvia_Image2}
                alt="Vishal Balyan"
              />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Vishal Balyan</div>
                <div className={css.thumbText}>"I'd recommend Chessvia to any aspiring coach looking to build their brand"</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img
                src={Naveen_Chessvia_Image}
                alt="Naveen Prabhu"
              />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Naveen Prabhu</div>
                <div className={css.thumbText}>"Chessvia's AI tools significantly improved my understanding of my coaching chess style"</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img
                src={Raleigh_Chessvia_Image}
                alt="Thomas Kung"
              />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Raleigh Potluri</div>
                <div className={css.thumbText}>"Chessvia fills a serious need in the Chess community and does it with a sweet AI angle"</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img
                src={Vasishta_Chessvia_Image}
                alt="Vasishta Tumuluri"
              />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Vasishta Tumuluri</div>
                <div className={css.thumbText}>"Chessvia is the only site I know that lets coaches make a living through coaching"</div>
              </div>
            </div>
          </div>
          <div className={css.thumbnailCard}>
            <div className={css.thumbnailInnerBox}>
              <img
                src={Trevor_Chessvia_Image}
                alt="Trevor Van Vleck"
              />
              <div className={css.thumbnailInfo}>
                <div className={css.thumbHeading}>Trevor Van Vleck</div>
                <div className={css.thumbText}>"Chessvia changed the way I improve my Chess game at a fraction of the cost of a Chess.com tutor"</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ChessviaTestimonial.defaultProps = {
  className: null,
  rootClassName: null,
};

ChessviaTestimonial.propTypes = {
  className: string,
  rootClassName: string,
};

export default ChessviaTestimonial;
